import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

export function tokenLifetime(token) {
  const { exp } = jwtDecode(token);

  return exp - Math.floor(Date.now() / 1000);
}

export function isTokenExpired(token) {
  if (!token) return true;

  const { exp } = jwtDecode(token);

  return exp - Math.floor(Date.now() / 1000) <= 0;
}

export function isTokenCloseToExpiry(token) {
  if (!token) return false;

  const { exp } = jwtDecode(token);

  return (exp - Math.floor(Date.now() / 1000)) < (5 * 60) && !!Cookies.get('accessToken');
}

export function setToken(type, token, expiration) {
  const attr = {
    expires: expiration,
    sameSite: 'strict',
    secure: true,
  };

  Cookies.set(type, token, attr);
}

export function clearToken(type) {
  Cookies.remove(type);
}

export function getToken(type) {
  return Cookies.get(type);
}
