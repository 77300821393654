import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import authSelectors from 'src/store/selectors/auth';

const AuthGuard = ({ children }) => {
  const user = useSelector(authSelectors.selectUser);

  if (!user.email) {
    return <Redirect to="/login" />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
