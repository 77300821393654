import {
  CHANGE_FLAG_FAILURE,
  CHANGE_FLAG_REQUEST,
  CHANGE_FLAG_SUCCESS,
  CHANGE_NOTE_FAILURE,
  CHANGE_NOTE_REQUEST,
  CHANGE_NOTE_SUCCESS,
} from '../actions/patients';

const initialState = {
  flag: {
    error: null,
    isError: false,
    isSending: false,
  },
  note: {
    error: null,
    isError: false,
    isSending: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_FLAG_REQUEST:
      return {
        ...state,
        flag: {
          ...state.flag,
          error: null,
          isError: false,
          isSending: true,
        },
      };
    case CHANGE_FLAG_SUCCESS:
      return {
        ...state,
        flag: {
          ...state.flag,
          isSending: false,
        },
      };
    case CHANGE_FLAG_FAILURE:
      return {
        ...state,
        flag: {
          ...state.flag,
          error: action.payload,
          isError: true,
          isSending: false,
        },
      };
    case CHANGE_NOTE_REQUEST:
      return {
        ...state,
        note: {
          ...state.note,
          error: null,
          isError: false,
          isSending: true,
        },
      };
    case CHANGE_NOTE_SUCCESS:
      return {
        ...state,
        note: {
          ...state.note,
          isSending: false,
        },
      };
    case CHANGE_NOTE_FAILURE:
      return {
        ...state,
        note: {
          ...state.note,
          error: action.payload,
          isError: true,
          isSending: false,
        },
      };
    default:
      return state;
  }
};
