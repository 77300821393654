import api from '../../api';

const PREFIX = '[USERS]';

export const GET_LIST_REQUEST = `${PREFIX} GET_LIST_REQUEST`;
export const GET_LIST_SUCCESS = `${PREFIX} GET_LIST_SUCCESS`;
export const GET_LIST_FAILURE = `${PREFIX} GET_LIST_FAILURE`;

const getListRequest = () => ({
  type: GET_LIST_REQUEST,
});

const getListSuccess = payload => ({
  payload,
  type: GET_LIST_SUCCESS,
});

const getListFailure = payload => ({
  payload,
  type: GET_LIST_FAILURE,
});

export const getUsersList = (link = null, params) => dispatch => {
  dispatch(getListRequest());

  return api.auth
    .list(link, params)
    .then(({ data }) => {
      dispatch(getListSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getListFailure(response && response.data));

      throw error;
    });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createRequest = () => ({
  type: CREATE_REQUEST,
});

const createSuccess = payload => ({
  payload,
  type: CREATE_SUCCESS,
});

const createFailure = payload => ({
  payload,
  type: CREATE_FAILURE,
});

export const createUser = formData => dispatch => {
  dispatch(createRequest());

  return api.auth
    .create(formData)
    .then(({ data }) => {
      dispatch(createSuccess(data));
      dispatch(getUsersList(null, { page: 1 }));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(createFailure(response && response.data));

      throw error;
    });
};

export const GET_USER_REQUEST = `${PREFIX} GET_USER_REQUEST`;
export const GET_USER_SUCCESS = `${PREFIX} GET_USER_SUCCESS`;
export const GET_USER_FAILURE = `${PREFIX} GET_USER_FAILURE`;

const getUserRequest = () => ({
  type: GET_USER_REQUEST,
});

const getUserSuccess = payload => ({
  payload,
  type: GET_USER_SUCCESS,
});

const getUserFailure = payload => ({
  payload,
  type: GET_USER_FAILURE,
});

export const getUser = id => dispatch => {
  dispatch(getUserRequest());

  return api.auth
    .getItem(id)
    .then(({ data }) => {
      dispatch(getUserSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getUserFailure(response && response.data));

      throw error;
    });
};

export const EDIT_REQUEST = `${PREFIX} EDIT_REQUEST`;
export const EDIT_SUCCESS = `${PREFIX} EDIT_SUCCESS`;
export const EDIT_FAILURE = `${PREFIX} EDIT_FAILURE`;

const editRequest = () => ({
  type: EDIT_REQUEST,
});

const editSuccess = payload => ({
  payload,
  type: EDIT_SUCCESS,
});

const editFailure = payload => ({
  payload,
  type: EDIT_FAILURE,
});

export const editUser = (id, formData) => dispatch => {
  dispatch(editRequest());

  return api.auth
    .editUser(id, formData)
    .then(({ data }) => {
      dispatch(editSuccess(data));
      dispatch(getUsersList(null, { page: 1 }));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(editFailure(response && response.data));

      throw error;
    });
};

export const RESET_USER_PASSWORD_REQUEST = `${PREFIX} RESET_USER_PASSWORD_REQUEST`;
export const RESET_USER_PASSWORD_SUCCESS = `${PREFIX} RESET_USER_PASSWORD_SUCCESS`;
export const RESET_USER_PASSWORD_FAILURE = `${PREFIX} RESET_USER_PASSWORD_FAILURE`;

const resetPasswordRequest = () => ({
  type: RESET_USER_PASSWORD_REQUEST,
});

const resetPasswordSuccess = () => ({
  type: RESET_USER_PASSWORD_SUCCESS,
});

const resetPasswordFailure = payload => ({
  payload,
  type: RESET_USER_PASSWORD_FAILURE,
});

export const resetUserPassword = id => dispatch => {
  dispatch(resetPasswordRequest());

  return api.auth
    .resetPassword(id)
    .then(({ data }) => {
      dispatch(resetPasswordSuccess());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(resetPasswordFailure(response && response.data));

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deleteUserRequest = () => ({
  type: DELETE_REQUEST,
});

const deleteUserSuccess = () => ({
  type: DELETE_SUCCESS,
});

const deleteUserFailure = payload => ({
  payload,
  type: DELETE_FAILURE,
});

export const deleteUser = id => dispatch => {
  dispatch(deleteUserRequest());

  return api.auth
    .delete(id)
    .then(({ data }) => {
      dispatch(deleteUserSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(deleteUserFailure(response && response.data));

      throw error;
    });
};

export const CLEAR_CURRENT_USER = `${PREFIX} CLEAR_CURRENT_USER`;

export const clearCurrentUser = () => ({
  type: CLEAR_CURRENT_USER,
});

