import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';

import PropTypes from 'prop-types';

import SplashScreen from 'src/components/SplashScreen';

import { REQUIRED_USER_ACTIONS } from 'src/common/constants';
import { getToken } from 'src/utils/jwt';

import {
  getMe, logout,
} from 'src/store/actions/auth';
import authSelectors from 'src/store/selectors/auth';
import request from 'src/utils/request';

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const requiredUserActions = useSelector(authSelectors.selectRequiredUserActions);
  const [
    isLoading,
    setLoading,
  ] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      request.initConfig({
        onLogout: () => {
          dispatch(logout());
          history.push('/login');
        },
      });

      if (getToken('accessToken') || getToken('refreshToken')) {
        try {
          await dispatch(getMe());
        } finally {
          setLoading(false);
        }
      }

      setLoading(false);
    };

    initAuth();
  }, [
    dispatch,
    history,
  ]);

  useEffect(() => {
    if (requiredUserActions &&
      requiredUserActions.length) {
      if (requiredUserActions.includes(REQUIRED_USER_ACTIONS.SET_OWN_PASSWORD)) {
        history.push('/set-password');
      } else {
        history.push('/terms-and-conditions');
      }
    }
  }, [
    requiredUserActions,
    history,
  ]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
};

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
