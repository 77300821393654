import api from '../../api';

const PREFIX = '[COMMON]';

export const GET_LANGUAGES_REQUEST = `${PREFIX} GET_LANGUAGES_REQUEST`;
export const GET_LANGUAGES_SUCCESS = `${PREFIX} GET_LANGUAGES_SUCCESS`;
export const GET_LANGUAGES_FAILURE = `${PREFIX} GET_LANGUAGES_FAILURE`;

const getLanguagesRequest = () => ({
  type: GET_LANGUAGES_REQUEST,
});

const getLanguagesSuccess = payload => ({
  payload,
  type: GET_LANGUAGES_SUCCESS,
});

const getLanguagesFailure = payload => ({
  payload,
  type: GET_LANGUAGES_FAILURE,
});

export const GET_COUNTRIES_REQUEST = `${PREFIX} GET_COUNTRIES_REQUEST`;
export const GET_COUNTRIES_SUCCESS = `${PREFIX} GET_COUNTRIES_SUCCESS`;
export const GET_COUNTRIES_FAILURE = `${PREFIX} GET_COUNTRIES_FAILURE`;

const getCountriesRequest = () => ({
  type: GET_COUNTRIES_REQUEST,
});

const getCountriesSuccess = payload => ({
  payload,
  type: GET_COUNTRIES_SUCCESS,
});

const getCountriesFailure = payload => ({
  payload,
  type: GET_COUNTRIES_FAILURE,
});

export const getLanguages = () => dispatch => {
  dispatch(getLanguagesRequest());

  return api.common
    .getLanguages()
    .then(({ data }) => {
      dispatch(getLanguagesSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getLanguagesFailure(response.data));

      throw error;
    });
};

export const getCountries = () => dispatch => {
  dispatch(getCountriesRequest());

  return api.common
    .getCountries()
    .then(({ data }) => {
      dispatch(getCountriesSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getCountriesFailure(response.data));

      throw error;
    });
};
