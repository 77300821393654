import React, {
  useEffect,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import {
  defineMessages, useIntl,
} from 'react-intl';

import {
  Box,
  Button,
  Link,
  makeStyles,
  Portal,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000,
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));

const messages = defineMessages({
  info: {
    defaultMessage: 'We use Cookies to ensure that we give you the best experience on our website. Read our',
    description: 'Cookies info',
    id: 'cookies.info',
  },
  policy: {
    defaultMessage: 'Privacy Policy',
    description: 'Privacy policy name',
    id: 'cookies.policy',
  },
  button: {
    defaultMessage: 'I agree',
    description: 'Cookies button text',
    id: 'cookies.button',
  },
});

const CookiesNotification = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [
    open,
    setOpen,
  ] = useState(false);

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography
          variant="body1"
          color="inherit"
        >
          {intl.formatMessage(messages.info)}
          {' '}
          <Link
            component="a"
            color="inherit"
            underline="always"
            href="/"
            target="_blank"
          >
            {intl.formatMessage(messages.policy)}
          </Link>
          .
        </Typography>
        <Box
          mt={2}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.action}
          >
            {intl.formatMessage(messages.button)}
          </Button>
        </Box>
      </div>
    </Portal>
  );
};

export default CookiesNotification;
