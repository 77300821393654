import React from 'react';
import { Router } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { Chart } from 'react-chartjs-2';

import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import ScrollReset from 'src/components/ScrollReset';

import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import chartjs from 'src/utils/chartjs';
import Routes from 'src/Routes';

import authSelectors from 'src/store/selectors/auth';

import './assets/scss/index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

const history = createBrowserHistory();

const jss = create({
  plugins: [...jssPreset().plugins],
});

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
  },
}));

const App = ({
  locale,
}) => {
  useStyles();
  const userRole = useSelector(authSelectors.selectUserRole);

  const { settings } = useSettings();

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale={locale}
        >
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <CookiesNotification />
                <Routes role={userRole} />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

App.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default App;

