import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { enableES5 } from 'immer';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';

import 'moment/locale/sv';
import 'moment/locale/nn';
import 'moment/locale/nb';
import 'moment/locale/fi';

import { SettingsProvider } from './context/SettingsContext';

import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { restoreSettings } from './utils/settings';
import getDefaultLocale, { getMomentLocale } from './utils/getDefaultLocale';

import messages_en from './assets/translations/en.json';
import messages_fi from './assets/translations/fi.json';
import messages_no from './assets/translations/no.json';
import messages_sv from './assets/translations/sv.json';

import App from './App';

const messages = {
  en: messages_en,
  fi: messages_fi,
  no: messages_no,
  sv: messages_sv,
};

const locale = getDefaultLocale();
const momentLocale = getMomentLocale();

const i18nConfig = {
  defaultLocale: 'sv',
  messages,
};

enableES5();

const store = configureStore();
const settings = restoreSettings();

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider
      locale={locale}
      defaultLocale={i18nConfig.defaultLocale}
      messages={i18nConfig.messages[locale]}
    >
      <SettingsProvider settings={settings}>
        <App locale={momentLocale} />
      </SettingsProvider>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
