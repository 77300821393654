import api from '../../api';

const PREFIX = '[SETTINGS]';

export const NOTIFICATIONS_DAYS_REQUEST = `${PREFIX} NOTIFICATIONS_DAYS_REQUEST`;
export const NOTIFICATIONS_DAYS_SUCCESS = `${PREFIX} NOTIFICATIONS_DAYS_SUCCESS`;
export const NOTIFICATIONS_DAYS_FAILURE = `${PREFIX} NOTIFICATIONS_DAYS_FAILURE`;

const notificationsDaysRequest = () => ({
  type: NOTIFICATIONS_DAYS_REQUEST,
});

const notificationsDaysSuccess = payload => ({
  payload,
  type: NOTIFICATIONS_DAYS_SUCCESS,
});

const notificationsDaysFailure = payload => ({
  payload,
  type: NOTIFICATIONS_DAYS_FAILURE,
});

export const getNotificationsDays = () => dispatch => {
  dispatch(notificationsDaysRequest());

  return api.settings
    .getNotificationsDays()
    .then(({ data }) => {
      dispatch(notificationsDaysSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(notificationsDaysFailure(response.data));

      throw error;
    });
};

export const setNotificationsDays = formData => dispatch => {
  dispatch(notificationsDaysRequest());

  return api.settings
    .setNotificationsDays(formData)
    .then(({ data } = {}) => {
      dispatch(notificationsDaysSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(notificationsDaysFailure(response));

      throw error;
    });
};
