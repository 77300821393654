import {
  CLEAR_CURRENT_USER,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  EDIT_FAILURE,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  GET_LIST_FAILURE,
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  RESET_USER_PASSWORD_FAILURE,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
} from '../actions/users';

const initialState = {
  currentUser: {
    data: {},
    error: null,
    isError: false,
    isFetching: false,
  },
  isSending: false,
  isSendingError: false,
  list: {
    allIds: [],
    byId: {},
    count: 0,
    error: null,
    isError: false,
    isFetching: false,
    next: null,
    previous: null,
  },
  sendingError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case GET_LIST_SUCCESS: {
      const {
        results, ...rest
      } = action.payload;

      const byId = results.reduce(
        (acc, item) => {
          acc[item.id] = item;

          return acc;
        },
        {}
      );

      const allIds = results.map(
        item => item.id
      );

      return {
        ...state,
        list: {
          ...state.list,
          ...rest,
          allIds,
          byId,
          isFetching: false,
        },
      };
    }
    case GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case CREATE_REQUEST:
    case EDIT_REQUEST:
    case RESET_USER_PASSWORD_REQUEST:
    case DELETE_REQUEST:
      return {
        ...state,
        isSending: true,
        isSendingError: false,
        sendingError: null,
      };
    case CREATE_SUCCESS:
    case EDIT_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: action.payload,
        },
        isSending: false,
      };
    case RESET_USER_PASSWORD_SUCCESS:
    case DELETE_SUCCESS:
      return {
        ...state,
        isSending: false,
      };
    case CREATE_FAILURE:
    case EDIT_FAILURE:
    case RESET_USER_PASSWORD_FAILURE:
    case DELETE_FAILURE:
      return {
        ...state,
        isSending: false,
        isSendingError: true,
        sendingError: action.payload,
      };
    case GET_USER_REQUEST:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: action.payload,
          isFetching: false,
        },
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: {
          ...initialState.currentUser,
        },
      };
    default:
      return state;
  }
};
