import auth from './auth';
import common from './common';
import materials from './materials';
import patients from './patients';
import products from './products';
import regions from './regions';
import reports from './reports';
import termsAndConditions from './termsAndConditions';
import version from './version';
import settings from './settings';

const api = {
  auth,
  common,
  materials,
  patients,
  products,
  regions,
  reports,
  termsAndConditions,
  version,
  settings,
};

export default api;
