import request from '../utils/request';

const BASE_URL = 'api/v1/auth';

export default {
  create: data => request.post(`${BASE_URL}/users/`, data),
  delete: id => request.delete(`${BASE_URL}/users/${id}/`),
  editUser: (id, data) => request.patch(`${BASE_URL}/users/${id}/`, data),
  forgotPassword: data => request.patch(`${BASE_URL}/users/reset_password_token/`, data),
  getItem: id => request.get(`${BASE_URL}/users/${id}/`),
  getMe: () => request.get(`${BASE_URL}/users/me/`),
  list: (link, params) => (link ? request.get(link) : request.get(`${BASE_URL}/users/`, { params })),
  login: data => request.post(`${BASE_URL}/token/`, data),
  resetPassword: id => request.get(`${BASE_URL}/users/${id}/set_temporary_password/`),
  setNewPassword: data => request.patch(`${BASE_URL}/users/reset_password/`, data),
  setPassword: data => request.post(`${BASE_URL}/users/me/password/`, data),
};
