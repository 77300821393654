import {
  CLEAR_DATA,
  GET_CHART_PATIENTS_FAILURE,
  GET_CHART_PATIENTS_REQUEST,
  GET_CHART_PATIENTS_SUCCESS,
  GET_DRESSINGS_STATS_FAILURE,
  GET_DRESSINGS_STATS_REQUEST,
  GET_DRESSINGS_STATS_SUCCESS,
  GET_PATIENTS_FAILURE,
  GET_PATIENTS_REQUEST,
  GET_PATIENTS_STATS_FAILURE,
  GET_PATIENTS_STATS_REQUEST,
  GET_PATIENTS_STATS_SUCCESS,
  GET_PATIENTS_SUCCESS,
  GET_PIE_CHART_END_FAILURE,
  GET_PIE_CHART_END_REQUEST,
  GET_PIE_CHART_END_SUCCESS,
  GET_PIE_CHART_START_FAILURE,
  GET_PIE_CHART_START_REQUEST,
  GET_PIE_CHART_START_SUCCESS,
  GET_REPORT_FAILURE,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_WOUNDS_STATS_FAILURE,
  GET_WOUNDS_STATS_REQUEST,
  GET_WOUNDS_STATS_SUCCESS,
  UPLOAD_REPORT_TEMPLATE_FAILURE,
  UPLOAD_REPORT_TEMPLATE_REQUEST,
  UPLOAD_REPORT_TEMPLATE_SUCCESS,
} from '../actions/reports';

const initialState = {
  chartPatients: {
    data: [],
    error: null,
    isError: false,
    isFetching: false,
  },
  file: {
    error: null,
    isError: false,
    isFetching: false,
  },
  pieChartStart: {
    data: {},
    error: null,
    isError: false,
    isFetching: false,
  },
  pieChartEnd: {
    data: {},
    error: null,
    isError: false,
    isFetching: false,
  },
  dressingsStats: {
    data: {},
    error: null,
    isError: false,
    isFetching: false,
  },
  patients: {
    allIds: [],
    byId: {},
    count: 0,
    error: null,
    isError: false,
    isFetching: false,
  },
  patientsStats: {
    data: {},
    error: null,
    isError: false,
    isFetching: false,
  },
  woundsStats: {
    data: {},
    error: null,
    isError: false,
    isFetching: false,
  },
  template: {
    error: null,
    isError: false,
    isSending: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENTS_STATS_REQUEST:
      return {
        ...state,
        patientsStats: {
          ...state.patientsStats,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_PATIENTS_STATS_SUCCESS: {
      return {
        ...state,
        patientsStats: {
          ...state.patientsStats,
          data: action.payload,
          isFetching: false,
        },
      };
    }
    case GET_PATIENTS_STATS_FAILURE:
      return {
        ...state,
        patientsStats: {
          ...state.patiensStats,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case GET_WOUNDS_STATS_REQUEST:
      return {
        ...state,
        woundsStats: {
          ...state.woundsStats,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_WOUNDS_STATS_SUCCESS: {
      return {
        ...state,
        woundsStats: {
          ...state.woundsStats,
          data: action.payload,
          isFetching: false,
        },
      };
    }
    case GET_WOUNDS_STATS_FAILURE:
      return {
        ...state,
        woundsStats: {
          ...state.patiensStats,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case GET_DRESSINGS_STATS_REQUEST:
      return {
        ...state,
        dressingsStats: {
          ...state.dressingsStats,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_DRESSINGS_STATS_SUCCESS: {
      return {
        ...state,
        dressingsStats: {
          ...state.dressingsStats,
          data: action.payload,
          isFetching: false,
        },
      };
    }
    case GET_DRESSINGS_STATS_FAILURE:
      return {
        ...state,
        dressingsStats: {
          ...state.dressingsStats,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case GET_PATIENTS_REQUEST:
      return {
        ...state,
        patients: {
          ...state.patients,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_PATIENTS_SUCCESS: {
      const {
        results,
        ...rest
      } = action.payload;

      const byId = results.reduce(
        (acc, item) => {
          acc[item.id] = item;

          return acc;
        },
        {}
      );

      const allIds = results.map(
        item => item.id
      );

      return {
        ...state,
        patients: {
          ...state.patients,
          ...rest,
          allIds,
          byId,
          isFetching: false,
        },
      };
    }
    case GET_PATIENTS_FAILURE:
      return {
        ...state,
        patients: {
          ...state.patients,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case GET_CHART_PATIENTS_REQUEST:
      return {
        ...state,
        chartPatients: {
          ...state.chartPatients,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_CHART_PATIENTS_SUCCESS: {
      const [
        added,
        removed,
        total,
      ] = action.payload;

      const totalParsed = Object.keys(total).map(label => ({
        year: label.slice(0, 4),
        month: label.slice(4, 6),
        total: total[label],
      }));

      return {
        ...state,
        chartPatients: {
          ...state.chartPatients,
          data: {
            added,
            removed,
            total: totalParsed,
          },
          isFetching: false,
        },
      };
    }
    case GET_CHART_PATIENTS_FAILURE:
      return {
        ...state,
        chartPatients: {
          ...state.chartPatients,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case GET_PIE_CHART_START_REQUEST:
      return {
        ...state,
        pieChartStart: {
          ...state.pieChartStart,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_PIE_CHART_START_SUCCESS: {
      const dict = action.payload.reduce(
        (acc, item) => {
          acc[item.woundType] = item;

          return acc;
        },
        {}
      );

      return {
        ...state,
        pieChartStart: {
          ...state.pieChartStart,
          data: dict,
          isFetching: false,
        },
      };
    }
    case GET_PIE_CHART_START_FAILURE:
      return {
        ...state,
        pieChartStart: {
          ...state.pieChartStart,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case GET_PIE_CHART_END_REQUEST:
      return {
        ...state,
        pieChartEnd: {
          ...state.pieChartEnd,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_PIE_CHART_END_SUCCESS: {
      const dict = action.payload.reduce(
        (acc, item) => {
          acc[item.woundType] = item;

          return acc;
        },
        {}
      );

      return {
        ...state,
        pieChartEnd: {
          ...state.pieChartEnd,
          data: dict,
          isFetching: false,
        },
      };
    }
    case GET_PIE_CHART_END_FAILURE:
      return {
        ...state,
        pieChartEnd: {
          ...state.pieChartEnd,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case GET_REPORT_REQUEST:
      return {
        ...state,
        file: {
          ...state.file,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_REPORT_SUCCESS: {
      return {
        ...state,
        file: {
          ...state.file,
          isFetching: false,
        },
      };
    }
    case GET_REPORT_FAILURE:
      return {
        ...state,
        file: {
          ...state.file,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case UPLOAD_REPORT_TEMPLATE_REQUEST:
      return {
        ...state,
        template: {
          ...state.template,
          error: null,
          isError: false,
          isSending: true,
        },
      };
    case UPLOAD_REPORT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        template: {
          ...state.template,
          isSending: false,
        },
      };
    }
    case UPLOAD_REPORT_TEMPLATE_FAILURE:
      return {
        ...state,
        template: {
          ...state.template,
          error: action.payload,
          isError: true,
          isSending: false,
        },
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
