import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import reducers from './reducers';

const loggerMiddleware = createLogger();
const { REACT_APP_ENABLE_REDUX_LOGGER: ENABLE_REDUX_LOGGER } = process.env;

export default function configureStore(initialState = {}) {
  const middlewares = [thunkMiddleware];
  let enhancer;

  if (ENABLE_REDUX_LOGGER) {
    middlewares.push(loggerMiddleware);
  }

  // eslint-disable-next-line no-underscore-dangle
  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer = compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line no-underscore-dangle
    );
  } else {
    enhancer = compose(applyMiddleware(...middlewares));
  }

  const store = createStore(
    reducers,
    initialState,
    enhancer
  );

  if (typeof window !== 'undefined') {
    window.store = store;
  }

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = reducers;

      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
