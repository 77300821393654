import api from '../../api';
import { getMe } from './auth';

const PREFIX = '[TERMS_AND_CONDITIONS]';

export const GET_REQUEST = `${PREFIX} GET_REQUEST`;
export const GET_SUCCESS = `${PREFIX} GET_SUCCESS`;
export const GET_FAILURE = `${PREFIX} GET_FAILURE`;

const getTermsAndConditionsRequest = () => ({
  type: GET_REQUEST,
});

const getTermsAndConditionsSuccess = payload => ({
  payload,
  type: GET_SUCCESS,
});

const getTermsAndConditionsFailure = payload => ({
  payload,
  type: GET_FAILURE,
});

export const getTermsAndConditions = () => dispatch => {
  dispatch(getTermsAndConditionsRequest());

  return api.termsAndConditions
    .getLatest()
    .then(({ data }) => {
      dispatch(getTermsAndConditionsSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getTermsAndConditionsFailure(response.data));

      throw error;
    });
};

export const SET_REQUEST = `${PREFIX} SET_REQUEST`;
export const SET_SUCCESS = `${PREFIX} SET_SUCCESS`;
export const SET_FAILURE = `${PREFIX} SET_FAILURE`;

const setTermsAndConditionsRequest = () => ({
  type: SET_REQUEST,
});

const setTermsAndConditionsSuccess = () => ({
  type: SET_SUCCESS,
});

const setTermsAndConditionsFailure = payload => ({
  payload,
  type: SET_FAILURE,
});

export const setTermsAndConditions = () => dispatch => {
  dispatch(setTermsAndConditionsRequest());

  return api.termsAndConditions
    .accept()
    .then(({ data } = {}) => {
      dispatch(setTermsAndConditionsSuccess());
      dispatch(getMe());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(setTermsAndConditionsFailure(response));

      throw error;
    });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createTermsAndConditionsRequest = () => ({
  type: CREATE_REQUEST,
});

const createTermsAndConditionsSuccess = payload => ({
  type: CREATE_SUCCESS,
  payload,
});

const createTermsAndConditionsFailure = payload => ({
  payload,
  type: CREATE_FAILURE,
});

export const createTermsAndConditions = text => dispatch => {
  dispatch(createTermsAndConditionsRequest());

  return api.termsAndConditions
    .create(text)
    .then(({ data } = {}) => {
      dispatch(createTermsAndConditionsSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(createTermsAndConditionsFailure(response));

      throw error;
    });
};
