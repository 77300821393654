import {
  CLEAR_ERROR,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  EDIT_FAILURE,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  GET_FULL_LIST_FAILURE,
  GET_FULL_LIST_REQUEST,
  GET_FULL_LIST_SUCCESS,
  GET_LIST_FAILURE,
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
} from '../actions/regions';

const initialState = {
  fullList: {
    allIds: [],
    byId: {},
    error: null,
    isError: false,
    isFetching: false,
  },
  isSending: false,
  isSendingError: false,
  list: {
    allIds: [],
    byId: {},
    error: null,
    isError: false,
    isFetching: false,
  },
  sendingError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case GET_LIST_SUCCESS: {
      const {
        results, ...rest
      } = action.payload;

      const byId = results.reduce(
        (acc, item) => {
          acc[item.id] = item;

          return acc;
        },
        {}
      );

      const allIds = results.map(
        item => item.id
      );

      return {
        ...state,
        list: {
          ...state.list,
          ...rest,
          allIds,
          byId,
          isFetching: false,
        },
      };
    }
    case GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case GET_FULL_LIST_REQUEST:
      return {
        ...state,
        fullList: {
          ...state.fullLIst,
          isFetching: true,
        },
      };
    case GET_FULL_LIST_SUCCESS: {
      const byId = action.payload.reduce(
        (acc, item) => {
          acc[item.id] = item;

          return acc;
        },
        {}
      );

      const allIds = action.payload.map(
        item => item.id
      );

      return {
        ...state,
        fullList: {
          ...state.fullList,
          allIds,
          byId,
          isFetching: false,
        },
      };
    }
    case GET_FULL_LIST_FAILURE:
      return {
        ...state,
        fullList: {
          ...state.fullList,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case CREATE_REQUEST:
    case EDIT_REQUEST:
      return {
        ...state,
        isSending: true,
        isSendingError: false,
        sendingError: null,
      };
    case CREATE_SUCCESS:
    case EDIT_SUCCESS:
      return {
        ...state,
        isSending: false,
      };
    case CREATE_FAILURE:
    case EDIT_FAILURE:
      return {
        ...state,
        isSending: false,
        isSendingError: true,
        sendingError: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        isSendingError: false,
        sendingError: null,
      };
    default:
      return state;
  }
};
