const language = navigator.language.split(/[-_]/)[0];

const getTranslationLocale = () => {
  switch (language) {
    case 'sv':
      return 'sv';
    case 'fi':
      return 'fi';
    case 'no':
    case 'nb':
    case 'nn':
      return 'no';
    default:
      return 'en';
  }
};

export const getMomentLocale = () => {
  const mapedLangauge = language === 'no' ? 'nb' : language;

  return [
    'en',
    'nb',
    'nn',
    'fi',
  ].includes(mapedLangauge) ?
    mapedLangauge :
    'en';
};

export default getTranslationLocale;
