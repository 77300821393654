/* eslint-disable no-useless-escape */
export const USER_ROLES = {
  ADMIN: 'w',
  EXPERT: 'e',
  NURSE: 'n',
  UNIT_MANAGER: 'm',
};

export const USER_ROLES_NAMES = {
  [USER_ROLES.EXPERT]: 'Expert',
  [USER_ROLES.UNIT_MANAGER]: 'Unit Manager',
  [USER_ROLES.NURSE]: 'Nurse',
  [USER_ROLES.ADMIN]: 'Admin',
};

export const GUEST_ROLE = 'Guest';

export const BACKEND_PAGE_SIZE = 10;

export const REQUIRED_USER_ACTIONS = {
  SET_OWN_PASSWORD: 'set_own_password',
};

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  ONE_WOUND: 'ONE_WOUND',
  UNICORN: 'UNICORN',
};

export const USER_ROLES_COLORS = {
  [USER_ROLES.ADMIN]: 'error',
  [USER_ROLES.NURSE]: 'primary',
  [USER_ROLES.EXPERT]: 'warning',
  [USER_ROLES.UNIT_MANAGER]: 'success',
};

export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-zA-Z])([^\s]+){6,}$/;

export const MATERIAL_CATEGORIES = {
  DEBRIDEMENT: 'deb',
  ACTIVE_TREATMENT: 'a_d',
  SOFTENING: 'spt',
  SECONDARY_DRESSINGS: 's_d',
  COMPRESSION: 'com',
};

export const MATERIAL_CATEGORIES_COLORS = {
  [MATERIAL_CATEGORIES.DEBRIDEMENT]: 'error',
  [MATERIAL_CATEGORIES.ACTIVE_TREATMENT]: 'primary',
  [MATERIAL_CATEGORIES.SOFTENING]: 'warning',
  [MATERIAL_CATEGORIES.SECONDARY_DRESSINGS]: 'secondary',
  [MATERIAL_CATEGORIES.COMPRESSION]: 'success',
};

export const URL_REGEX = /^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-.;:~#=_$+&amp;%!()[\]@*?,'\/]*)?$/;

export const WOUND_TYPES = {
  ALU: 'alu',
  DS: 'ds',
  HS: 'hs',
  MS: 'ms',
  NA: 'na',
  OTH: 'oth',
  TS: 'ts',
  VLU: 'vlu',
};

export const SORT_OPTIONS = {
  INITIALS: 'initials',
  NURSE: 'default_nurse',
  DRESSINGS_START: 'number_of_dressings_on_start',
  DRESSINGS_END: 'number_of_dressings_on_end',
  WOUND_PICTURE: 'last_wound_image_update_date',
  TREATMENT_PLAN: 'last_wound_treatment_plan_update_date',
  FLAG: 'flag',
  NOTE: 'note',
  REMOVED: 'is_archived',
};

export const OVERVIEW_ACTION_TYPES = {
  FOLLOW: 'follow',
  NO_ACTION: 'without_action',
  NON_HEALING: 'non_healing',
};

export const MULTIPLE_REGIONS_ROLES = [
  USER_ROLES.ADMIN,
  USER_ROLES.EXPERT,
];

export const WOUND_TYPES_LEGEND_OPTIONS = [
  WOUND_TYPES.TS,
  WOUND_TYPES.VLU,
  WOUND_TYPES.MS,
  WOUND_TYPES.DS,
  WOUND_TYPES.ALU,
  WOUND_TYPES.HS,
  WOUND_TYPES.OTH,
  WOUND_TYPES.NA,
];

export const USERS_SORT_OPTIONS = {
  CREATED_AT: 'created_at',
  NAME: 'name',
  REGION: 'region',
  ROLE: 'role',
};

export const MODES = {
  PUSH: 'push',
  APP: 'app',
};
