import React, {
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import {
  useDispatch, useSelector,
} from 'react-redux';

import {
  defineMessages,
  useIntl,
} from 'react-intl';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { logout } from 'src/store/actions/auth';
import authSelectors from 'src/store/selectors/auth';

const messages = defineMessages({
  accountOption: {
    defaultMessage: 'Account',
    description: 'Account link',
    id: 'topbar.account',
  },
  logoutOption: {
    defaultMessage: 'Sign out',
    description: 'Logout link',
    id: 'topbar.sign-out',
  },
});

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(authSelectors.selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const [
    isOpen,
    setOpen,
  ] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={user.avatar}
        />
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            {`${user.firstName} ${user.lastName}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>
          {intl.formatMessage(messages.logoutOption)}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
