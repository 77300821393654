import React, {
  useRef, useState,
} from 'react';

import {
  defineMessages,
  useIntl,
} from 'react-intl';

import { capitalCase } from 'change-case';
import { Settings as SettingsIcon } from 'react-feather';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  makeStyles,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/common/constants';

const useStyles = makeStyles(theme => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
}));

const messages = defineMessages({
  title: {
    defaultMessage: 'Settings',
    description: 'Settings title',
    id: 'settings.title',
  },
  button: {
    defaultMessage: 'Save settings',
    description: 'Button text',
    id: 'settings.button',
  },
  themeLabel: {
    defaultMessage: 'Theme',
    description: 'Theme label',
    id: 'settings.theme.label',
  },
  fontLabel: {
    defaultMessage: 'Responsive font sizes',
    description: 'Font label',
    id: 'settings.font.label',
  },
});

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const intl = useIntl();
  const {
    settings, saveSettings,
  } = useSettings();
  const [
    isOpen,
    setOpen,
  ] = useState(false);
  const [
    values,
    setValues,
  ] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={intl.formatMessage(messages.title)}>
        <IconButton
          color="inherit"
          onClick={handleOpen}
          ref={ref}
        >
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          {intl.formatMessage(messages.title)}
        </Typography>
        <Box
          mt={2}
          px={1}
        >
          <FormControlLabel
            control={(
              <Switch
                color="primary"
                checked={values.responsiveFontSizes}
                edge="start"
                name="direction"
                onChange={event => handleChange('responsiveFontSizes', event.target.checked)}
              />
            )}
            label={intl.formatMessage(messages.fontLabel)}
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label={intl.formatMessage(messages.themeLabel)}
            name="theme"
            onChange={event => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map(theme => (
              <option
                key={theme}
                value={theme}
              >
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSave}
          >
            {intl.formatMessage(messages.button)}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;
