import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import { USER_ROLES } from 'src/common/constants';

import authSelectors from 'src/store/selectors/auth';

const AdminGuard = ({ children }) => {
  const userRole = useSelector(authSelectors.selectUserRole);

  if (userRole !== USER_ROLES.ADMIN) {
    return <Redirect to="/403" />;
  }

  return children;
};

AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminGuard;
