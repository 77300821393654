import axios from 'axios';
import {
  toCamelCase,
  toSnakeCase,
} from 'case-converter';

import {
  clearToken,
  getToken,
  isTokenCloseToExpiry,
  setToken,
} from './jwt';

import getDefaultLocale from './getDefaultLocale';

const { REACT_APP_BACKEND_URL: BACKEND_URL } = process.env;

class Request {
  constructor() {
    this.initConfig();
    this.token = null;
  }

  setAuthorizationToken(token) {
    this.token = token;
    this.instance.defaults.headers.common.Authorization = `JWT ${token}`;
  }

  removeAuthorizationToken() {
    this.token = null;
    delete this.instance.defaults.headers.common.Authorization;
  }

  initConfig(args) {
    const token = getToken('accessToken');

    this.instance = axios.create({
      baseURL: BACKEND_URL,
      headers: {
        'Accept-Language': getDefaultLocale(),
      },
    });

    if (token) {
      this.setAuthorizationToken(token);
    }

    this.instance.interceptors.request.use(async config => {
      if (this.token && isTokenCloseToExpiry(this.token)) await this.refreshToken();

      return {
        ...config,
        data: config.data ? toSnakeCase(config.data) : config.data,
        params: config.params ? toSnakeCase(config.params) : config.params,
      };
    });

    this.instance.interceptors.response.use(response => ({
      ...response,
      data: toCamelCase(response.data),
    }), async error => {
      // Return any error which is not due to authentication back to
      // the calling service or if not valid credentials
      if (error.response.status !== 401) {
        return Promise.reject(this.parseError(error));
      }

      // Logout user if token refresh didn't work
      if (error.config.url === 'api/v1/auth/token/refresh/') {
        clearToken('accessToken');
        clearToken('refreshToken');

        this.removeAuthorizationToken();

        if (args && args.onLogout) {
          args.onLogout();
        }

        return Promise.reject(this.parseError(error));
      }

      if (getToken('refreshToken')) {
        // Try request again with new token
        await this.refreshToken();

        const { config } = error;

        delete config.headers.Authorization;

        return this.instance(config)
          .then(res => Promise.resolve({
            ...res,
            data: toCamelCase(res.data),
          }))
          .catch(err => Promise.reject(this.parseError(err)));
      }

      return Promise.reject(this.parseError(error));
    });
  }

  async refreshToken() {
    const refreshToken = getToken('refreshToken');

    if (!refreshToken) {
      return;
    }

    clearToken('accessToken');

    this.removeAuthorizationToken();

    const response = await this.instance.post('api/v1/auth/token/refresh/', { refresh: refreshToken });

    setToken('accessToken', response.data.access, (1 / 48));

    this.setAuthorizationToken(response.data.access);
  }

  parseError(error) {
    const { response = {} } = error;

    return {
      ...error,
      response: {
        ...response,
        data: {
          ...toCamelCase(response.data),
          _error: response.data ? response.data.non_field_errors : [],
        },
      },
    };
  }

  get(...args) {
    return this.instance.get(...args);
  }

  post(...args) {
    return this.instance.post(...args);
  }

  options(...args) {
    return this.instance.options(...args);
  }

  patch(...args) {
    return this.instance.patch(...args);
  }

  put(...args) {
    return this.instance.put(...args);
  }

  delete(...args) {
    return this.instance.delete(...args);
  }
}

export default new Request();
