import { combineReducers } from 'redux';

import auth from './auth';
import common from './common';
import materials from './materials';
import patients from './patients';
import regions from './regions';
import termsAndConditions from './termsAndConditions';
import reports from './reports';
import users from './users';
import version from './version';
import settings from './settings';

export default combineReducers({
  auth,
  common,
  materials,
  patients,
  regions,
  reports,
  termsAndConditions,
  users,
  version,
  settings,
});
