import {
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_LANGUAGES_FAILURE,
  GET_LANGUAGES_REQUEST,
  GET_LANGUAGES_SUCCESS,
} from '../actions/common';

const initialState = {
  countries: {
    data: {},
    error: null,
    isError: false,
    isFetching: false,
  },
  languages: {
    data: [],
    error: null,
    isError: false,
    isFetching: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES_REQUEST:
      return {
        ...state,
        countries: {
          ...state.countries,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          ...state.countries,
          data: action.payload,
          isFetching: false,
        },
      };
    case GET_COUNTRIES_FAILURE:
      return {
        ...state,
        countries: {
          ...state.countries,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case GET_LANGUAGES_REQUEST:
      return {
        ...state,
        languages: {
          ...state.languages,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: {
          ...state.languages,
          data: action.payload,
          isFetching: false,
        },
      };
    case GET_LANGUAGES_FAILURE:
      return {
        ...state,
        languages: {
          ...state.languages,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};
