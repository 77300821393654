import {
  CLEAR_CURRENT_MATERIAL,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  EDIT_FAILURE,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  GET_LIST_FAILURE,
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_MATERIAL_FAILURE,
  GET_MATERIAL_REQUEST,
  GET_MATERIAL_SUCCESS,
  GET_OPTIONS_FAILURE,
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
} from '../actions/materials';

const initialState = {
  currentItem: {
    data: {},
    error: null,
    isFetching: false,
    isFetchingError: false,
    isSending: false,
    isSendingError: false,
  },
  list: {
    allIds: [],
    byId: {},
    count: 0,
    error: null,
    isError: false,
    isFetching: false,
  },
  options: {
    data: {
      fields: {},
      languages: {},
    },
    error: null,
    isFetching: false,
    isFetchingError: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case GET_LIST_SUCCESS: {
      const {
        results, ...rest
      } = action.payload;

      const byId = results.reduce(
        (acc, item) => {
          acc[item.id] = item;

          return acc;
        },
        {}
      );

      const allIds = results.map(
        item => item.id
      );

      return {
        ...state,
        list: {
          ...state.list,
          ...rest,
          allIds,
          byId,
          isFetching: false,
        },
      };
    }
    case GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    case GET_MATERIAL_REQUEST:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          error: null,
          isFetching: true,
          isFetchingError: false,
        },
      };
    case GET_MATERIAL_SUCCESS:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          data: action.payload,
          isFetching: false,
        },
      };
    case GET_MATERIAL_FAILURE:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          error: action.payload,
          isFetching: false,
          isFetchingError: true,
        },
      };
    case CREATE_REQUEST:
    case EDIT_REQUEST:
    case DELETE_REQUEST:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          error: null,
          isSending: true,
          isSendingError: false,
        },
      };
    case CREATE_SUCCESS:
    case EDIT_SUCCESS:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isSending: false,
        },
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isSending: false,
        },
      };
    case CREATE_FAILURE:
    case EDIT_FAILURE:
    case DELETE_FAILURE:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          error: action.payload,
          isSending: false,
          isSendingError: false,
        },
      };
    case CLEAR_CURRENT_MATERIAL:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          data: {},
        },
      };
    case GET_OPTIONS_REQUEST:
      return {
        ...state,
        options: {
          ...state.options,
          isFetching: true,
        },
      };
    case GET_OPTIONS_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          data: action.payload,
          isFetching: false,
        },
      };
    case GET_OPTIONS_FAILURE:
      return {
        ...state,
        options: {
          ...state.options,
          error: action.payload,
          isFetching: false,
          isFetchingError: true,
        },
      };
    default:
      return state;
  }
};
