import { createSelector } from 'reselect';

const getAuthState = state => state.auth;

export default {
  selectAuthError: createSelector(getAuthState, ({ error }) => error),
  selectAuthErrorStatus: createSelector(getAuthState, ({ error }) => error && error.status),
  selectAuthIsError: createSelector(getAuthState, ({ isError }) => isError),
  selectPasswordWasChanged: createSelector(getAuthState, ({ passwordChanged }) => passwordChanged),
  selectRequiredUserActions: createSelector(
    getAuthState,
    ({ requiredUserActions }) => requiredUserActions
  ),
  selectUser: createSelector(getAuthState, ({ me }) => me),
  selectUserRole: createSelector(getAuthState, ({ me }) => me.role),
};
