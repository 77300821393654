import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  image: {
    width: 132,
  },
}));

const Logo = ({
  inverted,
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <img
      className={clsx(classes.image, className)}
      alt="Logo"
      src={inverted ? '/static/logo-inverted.svg' : '/static/logo.svg'}
      {...rest}
    />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
};

Logo.defaultProps = {
  className: '',
  inverted: false,
};

export default Logo;
