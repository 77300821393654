import api from '../../api';

const PREFIX = '[REPORTS]';

export const GET_PATIENTS_STATS_REQUEST = `${PREFIX} GET_PATIENTS_STATS_REQUEST`;
export const GET_PATIENTS_STATS_SUCCESS = `${PREFIX} GET_PATIENTS_STATS_SUCCESS`;
export const GET_PATIENTS_STATS_FAILURE = `${PREFIX} GET_PATIENTS_STATS_FAILURE`;

const getPatientsStatsRequest = () => ({
  type: GET_PATIENTS_STATS_REQUEST,
});

const getPatientsStatsSuccess = payload => ({
  payload,
  type: GET_PATIENTS_STATS_SUCCESS,
});

const getPatientsStatsFailure = payload => ({
  payload,
  type: GET_PATIENTS_STATS_FAILURE,
});

export const getPatientsStats = params => dispatch => {
  dispatch(getPatientsStatsRequest());

  return api.reports
    .patients(params)
    .then(({ data }) => {
      dispatch(getPatientsStatsSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getPatientsStatsFailure(response && response.data));

      throw error;
    });
};

export const GET_WOUNDS_STATS_REQUEST = `${PREFIX} GET_WOUNDS_STATS_REQUEST`;
export const GET_WOUNDS_STATS_SUCCESS = `${PREFIX} GET_WOUNDS_STATS_SUCCESS`;
export const GET_WOUNDS_STATS_FAILURE = `${PREFIX} GET_WOUNDS_STATS_FAILURE`;

const getWoundsStatsRequest = () => ({
  type: GET_WOUNDS_STATS_REQUEST,
});

const getWoundsStatsSuccess = payload => ({
  payload,
  type: GET_WOUNDS_STATS_SUCCESS,
});

const getWoundsStatsFailure = payload => ({
  payload,
  type: GET_WOUNDS_STATS_FAILURE,
});

export const getWoundsStats = (region, from, to) => dispatch => {
  dispatch(getWoundsStatsRequest());

  return api.reports
    .wounds(region, from, to)
    .then(({ data }) => {
      dispatch(getWoundsStatsSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getWoundsStatsFailure(response && response.data));

      throw error;
    });
};

export const GET_DRESSINGS_STATS_REQUEST = `${PREFIX} GET_DRESSINGS_STATS_REQUEST`;
export const GET_DRESSINGS_STATS_SUCCESS = `${PREFIX} GET_DRESSINGS_STATS_SUCCESS`;
export const GET_DRESSINGS_STATS_FAILURE = `${PREFIX} GET_DRESSINGS_STATS_FAILURE`;

const getDressingsStatsRequest = () => ({
  type: GET_DRESSINGS_STATS_REQUEST,
});

const getDressingsStatsSuccess = payload => ({
  payload,
  type: GET_DRESSINGS_STATS_SUCCESS,
});

const getDressingsStatsFailure = payload => ({
  payload,
  type: GET_DRESSINGS_STATS_FAILURE,
});

export const getDressingsStats = (region, from, to) => dispatch => {
  dispatch(getDressingsStatsRequest());

  return api.reports
    .dressings(region, from, to)
    .then(({ data }) => {
      dispatch(getDressingsStatsSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getDressingsStatsFailure(response && response.data));

      throw error;
    });
};

export const GET_CHART_PATIENTS_REQUEST = `${PREFIX} GET_CHART_PATIENTS_REQUEST`;
export const GET_CHART_PATIENTS_SUCCESS = `${PREFIX} GET_CHART_PATIENTS_SUCCESS`;
export const GET_CHART_PATIENTS_FAILURE = `${PREFIX} GET_CHART_PATIENTS_FAILURE`;

const getChartPatientsRequest = () => ({
  type: GET_CHART_PATIENTS_REQUEST,
});

const getChartPatientsSuccess = payload => ({
  payload,
  type: GET_CHART_PATIENTS_SUCCESS,
});

const getChartPatientsFailure = payload => ({
  payload,
  type: GET_CHART_PATIENTS_FAILURE,
});

export const getChartPatientsData = params => dispatch => {
  dispatch(getChartPatientsRequest());

  return Promise.all([
    api.reports.chartPatientsAdded(params),
    api.reports.chartPatientsRemoved(params),
    api.reports.chartPatientsTotal(params),
  ])
    .then(responses => {
      const data = responses.map(response => response.data);

      dispatch(getChartPatientsSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getChartPatientsFailure(response && response.data));

      throw error;
    });
};

export const GET_PATIENTS_REQUEST = `${PREFIX} GET_PATIENTS_REQUEST`;
export const GET_PATIENTS_SUCCESS = `${PREFIX} GET_PATIENTS_SUCCESS`;
export const GET_PATIENTS_FAILURE = `${PREFIX} GET_PATIENTS_FAILURE`;

const getPatientsRequest = () => ({
  type: GET_PATIENTS_REQUEST,
});

const getPatientsSuccess = payload => ({
  payload,
  type: GET_PATIENTS_SUCCESS,
});

const getPatientsFailure = payload => ({
  payload,
  type: GET_PATIENTS_FAILURE,
});

export const getPatients = (params, link) => dispatch => {
  dispatch(getPatientsRequest());

  return api.reports
    .list(link, params)
    .then(({ data }) => {
      dispatch(getPatientsSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getPatientsFailure(response && response.data));

      throw error;
    });
};

export const GET_PIE_CHART_START_REQUEST = `${PREFIX} GET_PIE_CHART_START_REQUEST`;
export const GET_PIE_CHART_START_SUCCESS = `${PREFIX} GET_PIE_CHART_START_SUCCESS`;
export const GET_PIE_CHART_START_FAILURE = `${PREFIX} GET_PIE_CHART_START_FAILURE`;

const getPieChartStartRequest = () => ({
  type: GET_PIE_CHART_START_REQUEST,
});

const getPieChartStartSuccess = payload => ({
  payload,
  type: GET_PIE_CHART_START_SUCCESS,
});

const getPieChartStartFailure = payload => ({
  payload,
  type: GET_PIE_CHART_START_FAILURE,
});

export const getPieChartStart = params => dispatch => {
  dispatch(getPieChartStartRequest());

  return api.reports
    .pieChart(params)
    .then(({ data }) => {
      dispatch(getPieChartStartSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getPieChartStartFailure(response && response.data));

      throw error;
    });
};

export const GET_PIE_CHART_END_REQUEST = `${PREFIX} GET_PIE_CHART_END_REQUEST`;
export const GET_PIE_CHART_END_SUCCESS = `${PREFIX} GET_PIE_CHART_END_SUCCESS`;
export const GET_PIE_CHART_END_FAILURE = `${PREFIX} GET_PIE_CHART_END_FAILURE`;

const getPieChartEndRequest = () => ({
  type: GET_PIE_CHART_END_REQUEST,
});

const getPieChartEndSuccess = payload => ({
  payload,
  type: GET_PIE_CHART_END_SUCCESS,
});

const getPieChartEndFailure = payload => ({
  payload,
  type: GET_PIE_CHART_END_FAILURE,
});

export const getPieChartEnd = params => dispatch => {
  dispatch(getPieChartEndRequest());

  return api.reports
    .pieChart(params)
    .then(({ data }) => {
      dispatch(getPieChartEndSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getPieChartEndFailure(response && response.data));

      throw error;
    });
};

export const CLEAR_DATA = `${PREFIX} CLEAR_DATA`;

export const clearData = () => ({
  type: CLEAR_DATA,
});

export const GET_REPORT_REQUEST = `${PREFIX} GET_REPORT_REQUEST`;
export const GET_REPORT_SUCCESS = `${PREFIX} GET_REPORT_SUCCESS`;
export const GET_REPORT_FAILURE = `${PREFIX} GET_REPORT_FAILURE`;

const getReportRequest = () => ({
  type: GET_REPORT_REQUEST,
});

const getReportSuccess = () => ({
  type: GET_REPORT_SUCCESS,
});

const getReportFailure = payload => ({
  payload,
  type: GET_REPORT_FAILURE,
});

export const getReport = params => dispatch => {
  dispatch(getReportRequest());

  return api.reports
    .export(params)
    .then(({ data }) => {
      dispatch(getReportSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getReportFailure(response && response.data));

      throw error;
    });
};

export const UPLOAD_REPORT_TEMPLATE_REQUEST = `${PREFIX} UPLOAD_REPORT_TEMPLATE_REQUEST`;
export const UPLOAD_REPORT_TEMPLATE_SUCCESS = `${PREFIX} UPLOAD_REPORT_TEMPLATE_SUCCESS`;
export const UPLOAD_REPORT_TEMPLATE_FAILURE = `${PREFIX} UPLOAD_REPORT_TEMPLATE_FAILURE`;

const uploadReportTemplateRequest = () => ({
  type: UPLOAD_REPORT_TEMPLATE_REQUEST,
});

const uploadReportTemplateSuccess = () => ({
  type: UPLOAD_REPORT_TEMPLATE_SUCCESS,
});

const uploadReportTemplateFailure = payload => ({
  payload,
  type: UPLOAD_REPORT_TEMPLATE_FAILURE,
});

export const uploadReportTemplate = formData => dispatch => {
  dispatch(uploadReportTemplateRequest());

  return api.reports
    .uploadTemplate(formData)
    .then(({ data }) => {
      dispatch(uploadReportTemplateSuccess());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(uploadReportTemplateFailure(response && response.data));

      throw error;
    });
};
