/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  matchPath, useLocation,
} from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  defineMessages,
  useIntl,
} from 'react-intl';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import HealingIcon from '@material-ui/icons/HealingOutlined';
import {
  BarChart2 as BarChartIcon,
  BookOpen as BookIcon,
  Map as MapIcon,
  Tag as TagIcon,
  Users as UsersIcon,
} from 'react-feather';

import authSelectors from 'src/store/selectors/auth';

import Logo from 'src/components/Logo';
import { USER_ROLES } from 'src/common/constants';
import NavItem from './NavItem';

const navConfig = role => ([
  {
    subheader: 'dashboard',
    items: [
      {
        href: '/app/dashboard',
        icon: BarChartIcon,
        title: 'overview',
      },
    ],
  },
  ...(
    role === USER_ROLES.EXPERT ?
      [
        {
          subheader: 'materialLibrary',
          items: [
            {
              href: '/app/material-library',
              icon: HealingIcon,
              title: 'materials',
            },
          ],
        },
      ] :
      []
  ),
  ...(
    role === USER_ROLES.ADMIN ?
      [
        {
          subheader: 'adminPanel',
          items: [
            {
              title: 'users',
              icon: UsersIcon,
              href: '/app/admin/users',
            },
            {
              title: 'municipalities',
              icon: MapIcon,
              href: '/app/admin/municipalities',
            },
            {
              title: 'termsAndConditions',
              icon: BookIcon,
              href: '/app/admin/terms-and-conditions',
            },
            {
              title: 'settings',
              icon: TagIcon,
              href: '/app/admin/settings',
            },
          ],
        },
      ] :
      []
  ),
]);

const renderNavItems = ({
  items, ...rest
}) => (
  <List disablePadding>
    {items.reduce(
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        ...rest,
      }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth = 0,
}) => {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      exact: false,
      path: item.href,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.items,
          pathname,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
};

const useStyles = makeStyles(() => ({
  avatar: {
    width: 64,
    height: 64,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  mobileDrawer: {
    width: 256,
  },
}));

const messages = defineMessages({
  e: {
    defaultMessage: 'Expert',
    description: 'Expert role name',
    id: 'roles.expert',
  },
  m: {
    defaultMessage: 'Unit Manager',
    description: 'Manager role name',
    id: 'roles.manager',
  },
  n: {
    defaultMessage: 'Nurse',
    description: 'Nurse role name',
    id: 'roles.nurse',
  },
  w: {
    defaultMessage: 'Admin',
    description: 'Admin role name',
    id: 'roles.admin',
  },
  adminPanel: {
    defaultMessage: 'Admin panel',
    description: 'Admin panel subheader',
    id: 'navbar.admin-panel',
  },
  settings: {
    defaultMessage: 'Settings',
    description: 'Settings subheader',
    id: 'navbar.settings',
  },
  reports: {
    defaultMessage: 'Reports',
    description: 'Reports subheader',
    id: 'navbar.reports',
  },
  dashboard: {
    defaultMessage: 'Dashboard',
    description: 'Dashboard subheader',
    id: 'navbar.dashboard',
  },
  materialLibrary: {
    defaultMessage: 'Material library',
    description: 'Material Library subheader',
    id: 'navbar.material-library',
  },
});

const NavBar = ({
  openMobile,
  onMobileClose,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const intl = useIntl();
  const user = useSelector(authSelectors.selectUser);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <Avatar
              alt="User"
              className={classes.avatar}
              src={user.avatar}
            />
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Typography
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstName} ${user.lastName}`}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {intl.formatMessage(messages[user.role])}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig(user.role).map(config => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {intl.formatMessage(messages[config.subheader])}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func.isRequired,
  openMobile: PropTypes.bool.isRequired,
};

export default NavBar;
