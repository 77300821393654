import request from '../utils/request';

const BASE_URL = 'api/v1/materials';

export default {
  create: data => request.post(`${BASE_URL}/`, data),
  delete: id => request.delete(`${BASE_URL}/${id}/`),
  getItem: id => request.get(`${BASE_URL}/${id}/`),
  list: (link, limit) => request.get(link || `${BASE_URL}/?limit=${limit}`),
  options: () => request.options(`${BASE_URL}/`),
  update: (id, data) => request.patch(`${BASE_URL}/${id}/`, data),
};
