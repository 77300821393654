import api from '../../api';

const PREFIX = '[PATIENTS]';

export const CHANGE_FLAG_REQUEST = `${PREFIX} CHANGE_FLAG_REQUEST`;
export const CHANGE_FLAG_SUCCESS = `${PREFIX} CHANGE_FLAG_SUCCESS`;
export const CHANGE_FLAG_FAILURE = `${PREFIX} CHANGE_FLAG_FAILURE`;

const changeFlagRequest = () => ({
  type: CHANGE_FLAG_REQUEST,
});

const changeFlagSuccess = () => ({
  type: CHANGE_FLAG_SUCCESS,
});

const changeFlagFailure = payload => ({
  payload,
  type: CHANGE_FLAG_FAILURE,
});

export const changeFlag = (id, flag) => dispatch => {
  dispatch(changeFlagRequest());

  return api.patients
    .flag(id, { flag })
    .then(({ data }) => {
      dispatch(changeFlagSuccess());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(changeFlagFailure(response && response.data));

      throw error;
    });
};

export const CHANGE_NOTE_REQUEST = `${PREFIX} CHANGE_NOTE_REQUEST`;
export const CHANGE_NOTE_SUCCESS = `${PREFIX} CHANGE_NOTE_SUCCESS`;
export const CHANGE_NOTE_FAILURE = `${PREFIX} CHANGE_NOTE_FAILURE`;

const changeNoteRequest = () => ({
  type: CHANGE_NOTE_REQUEST,
});

const changeNoteSuccess = () => ({
  type: CHANGE_NOTE_SUCCESS,
});

const changeNoteFailure = payload => ({
  payload,
  type: CHANGE_NOTE_FAILURE,
});

export const changeNote = (id, note) => dispatch => {
  dispatch(changeNoteRequest());

  return api.patients
    .note(id, { note })
    .then(({ data }) => {
      dispatch(changeNoteSuccess());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(changeNoteFailure(response && response.data));

      throw error;
    });
};

