/* eslint-disable react/no-array-index-key */
import React, {
  Fragment,
  lazy,
  Suspense,
} from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import AdminGuard from 'src/components/AdminGuard';
import GuestGuard from 'src/components/GuestGuard';
import { USER_ROLES } from 'src/common/constants';

const routesConfig = role => {
  const adminRoutes = [
    {
      path: '/app/admin',
      guard: AdminGuard,
      routes: [
        {
          exact: true,
          path: '/app/admin',
          component: () => <Redirect to="/app/admin/users" />,
        },
        {
          exact: true,
          path: '/app/admin/users',
          component: lazy(() => import('src/views/UserList')),
        },
        {
          exact: true,
          path: '/app/admin/users/:userId',
          component: lazy(() => import('src/views/UserDetails')),
        },
        {
          exact: true,
          path: '/app/admin/users/:userId/edit',
          component: lazy(() => import('src/views/UserEdit')),
        },
        {
          exact: true,
          path: '/app/admin/municipalities',
          component: lazy(() => import('src/views/RegionList')),
        },
        {
          exact: true,
          path: '/app/admin/terms-and-conditions',
          component: lazy(() => import('./views/TermsAndConditionsEdit')),
        },
        {
          exact: true,
          path: '/app/admin/settings',
          component: lazy(() => import('./views/Settings')),
        },
        {
          component: () => <Redirect to="/404" />,
        },
      ],
    },
    {
      exact: true,
      path: '/app/admin',
      component: () => <Redirect to="/app/admin/users" />,
    },
  ];

  const expertRoutes = [
    {
      exact: true,
      path: '/app/material-library',
      component: lazy(() => import('src/views/MaterialLibrary')),
    },
    {
      exact: true,
      path: '/app/material-library/create',
      component: lazy(() => import('src/views/MaterialEdit')),
    },
    {
      exact: true,
      path: '/app/material-library/edit/:materialId',
      component: lazy(() => import('src/views/MaterialEdit')),
    },
  ];

  return role === USER_ROLES.NURSE ?
    [
      {
        path: '/',
        exact: true,
        component: () => <Redirect to="/auth/nurse-info" />,
      },
      {
        route: '*',
        layout: MainLayout,
        routes: [
          {
            path: '/auth/nurse-info',
            exact: true,
            component: lazy(() => import('./views/NurseView')),
          },
          {
            component: () => <Redirect to="/auth/nurse-info" />,
          },
        ],
      },
    ] :
    [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/dashboard" />,
      },
      {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
          {
            exact: true,
            path: '/app',
            component: () => <Redirect to="/app/dashboard" />,
          },
          {
            exact: true,
            path: '/app/dashboard',
            component: lazy(() => import('src/views/DashboardView')),
          },
          ...(role === USER_ROLES.ADMIN ? adminRoutes : []),
          ...(role === USER_ROLES.EXPERT ? expertRoutes : []),
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '*',
        layout: MainLayout,
        routes: [
          {
            exact: true,
            path: '/404',
            component: lazy(() => import('src/views/ErrorPages/Error404')),
          },
          {
            exact: true,
            path: '/403',
            component: lazy(() => import('src/views/ErrorPages/Error403')),
          },
          {
            exact: true,
            path: '/401',
            component: lazy(() => import('src/views/ErrorPages/Error401')),
          },
          {
            exact: true,
            path: '/500',
            component: lazy(() => import('src/views/ErrorPages/Error500')),
          },
          {
            exact: true,
            guard: GuestGuard,
            path: '/login',
            component: lazy(() => import('src/views/Login')),
          },
          {
            exact: true,
            guard: GuestGuard,
            path: '/set-password',
            component: lazy(() => import('src/views/ChangePassword')),
          },
          {
            exact: true,
            path: '/terms-and-conditions',
            component: lazy(() => import('./views/TermsAndConditions')),
          },
          {
            exact: true,
            path: '/reset-password',
            component: lazy(() => import('src/views/ResetPassword')),
          },
          {
            exact: true,
            path: '/forgot-password',
            component: lazy(() => import('src/views/ForgotPassword')),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
    ];
};

const renderRoutes = routes => (routes ?
  (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ?
                      renderRoutes(route.routes) :
                      <Component {...props} />}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) :
  null
);

const Routes = ({ role }) => renderRoutes(routesConfig(role));

export default Routes;
