import {
  NOTIFICATIONS_DAYS_FAILURE,
  NOTIFICATIONS_DAYS_REQUEST,
  NOTIFICATIONS_DAYS_SUCCESS,
} from '../actions/settings';

const initialState = {
  notificationsDays: {
    data: {
      pushNotificationWoundImageReminderInterval: 0,
      inappNotificationWoundImageReminderInterval: 0,
    },
    error: null,
    isError: false,
    isFetching: false,
    isSending: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_DAYS_REQUEST:
      return {
        ...state,
        notificationsDays: {
          ...state.notificationsDays,
          error: null,
          isError: false,
          isFetching: true,
        },
      };
    case NOTIFICATIONS_DAYS_SUCCESS:
      return {
        ...state,
        notificationsDays: {
          ...state.notificationsDays,
          data: action.payload,
          isFetching: false,
        },
      };
    case NOTIFICATIONS_DAYS_FAILURE:
      return {
        ...state,
        notificationsDays: {
          ...state.notificationsDays,
          error: action.payload,
          isError: true,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};
