import request from '../utils/request';

const BASE_URL = 'api/v1';

export default {
  chartPatientsAdded: params => request.get(`${BASE_URL}/chart_patients_added/`, { params }),
  chartPatientsRemoved: params => request.get(`${BASE_URL}/chart_patients_removed/`, { params }),
  chartPatientsTotal: params => request.get(`${BASE_URL}/chart_patients_total/`, { params }),
  dressings: params => request.get(`${BASE_URL}/report_bandages/`, { params }),
  uploadTemplate: data => request.put(`${BASE_URL}/upload_template/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  export: params => request.get(`${BASE_URL}/export_report/`, {
    params,
    responseType: 'arraybuffer',
  }),
  list: (link, params) => (link ? request.get(link) : request.get(`${BASE_URL}/report_patients_details/`, { params })),
  patients: params => request.get(`${BASE_URL}/report_patients/`, { params }),
  pieChart: params => request.get(`${BASE_URL}/pie_chart/`, { params }),
  wounds: params => request.get(`${BASE_URL}/report_wounds/`, { params }),
};
