import api from '../../api';
import request from '../../utils/request';
import {
  clearToken,
  setToken,
} from '../../utils/jwt';

const PREFIX = '[AUTH]';

export const CLEAR_ERROR = `${PREFIX} CLEAR_ERROR`;

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const GET_ME_REQUEST = `${PREFIX} GET_ME_REQUEST`;
export const GET_ME_SUCCESS = `${PREFIX} GET_ME_SUCCESS`;
export const GET_ME_FAILURE = `${PREFIX} GET_ME_FAILURE`;

export const USER_ACTION_REQUESTED = `${PREFIX} USER_ACTIONS_REQUESTED`;

const getMeRequest = () => ({
  type: GET_ME_REQUEST,
});

const getMeSuccess = payload => ({
  payload,
  type: GET_ME_SUCCESS,
});

const getMeFailure = payload => ({
  payload,
  type: GET_ME_FAILURE,
});

const usersActionRequested = payload => ({
  payload,
  type: USER_ACTION_REQUESTED,
});

export const getMe = () => dispatch => {
  dispatch(getMeRequest());

  return api.auth
    .getMe()
    .then(({ data }) => {
      dispatch(getMeSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getMeFailure(response.data));

      if (response && response.status === 409) {
        dispatch(clearError());
        dispatch(usersActionRequested(response.data && response.data.pendingActions));
      }

      throw error;
    });
};

export const LOGIN_REQUEST = `${PREFIX} LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${PREFIX} LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${PREFIX} LOGIN_FAILURE`;

const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

const loginFailure = payload => ({
  payload,
  type: LOGIN_FAILURE,
});

export const login = payload => dispatch => {
  dispatch(loginRequest());

  return api.auth
    .login(payload)
    .then(({ data } = {}) => {
      dispatch(loginSuccess());
      request.setAuthorizationToken(data.access);
      setToken('accessToken', data.access, (1 / 48));
      setToken('refreshToken', data.refresh, 365);
      dispatch(getMe());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(loginFailure(response));

      throw error;
    });
};

export const LOGOUT_USER = `${PREFIX} LOGOUT_USER`;

export const logout = () => {
  request.removeAuthorizationToken();
  clearToken('accessToken');
  clearToken('refreshToken');

  return {
    type: LOGOUT_USER,
  };
};

export const SET_IS_AUTHENTICATED = `${PREFIX} SET_IS_AUTHENTICATED`;

export const setIsAuthenticated = payload => ({
  payload,
  type: SET_IS_AUTHENTICATED,
});

export const GENERATE_ERROR = `${PREFIX} GENERATE_ERROR`;

export const generateError = payload => ({
  payload,
  type: GENERATE_ERROR,
});

export const SET_PASSWORD_REQUEST = `${PREFIX} SET_PASSWORD_REQUEST`;
export const SET_PASSWORD_SUCCESS = `${PREFIX} SET_PASSWORD_SUCCESS`;
export const SET_PASSWORD_FAILURE = `${PREFIX} SET_PASSWORD_FAILURE`;

const setPasswordRequest = () => ({
  type: SET_PASSWORD_REQUEST,
});

const setPasswordSuccess = () => ({
  type: SET_PASSWORD_SUCCESS,
});

const setPasswordFailure = payload => ({
  payload,
  type: SET_PASSWORD_FAILURE,
});

export const setPassword = payload => dispatch => {
  dispatch(setPasswordRequest());

  return api.auth
    .setPassword(payload)
    .then(({ data } = {}) => {
      dispatch(setPasswordSuccess());
      dispatch(getMe());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(setPasswordFailure(response));

      throw error;
    });
};

export const resetPassword = payload => dispatch => {
  dispatch(setPasswordRequest());

  return api.auth
    .setNewPassword(payload)
    .then(({ data } = {}) => {
      dispatch(setPasswordSuccess());
      request.setAuthorizationToken(data.access);
      setToken('accessToken', data.access, (1 / 48));
      setToken('refreshToken', data.refresh, 365);
      dispatch(getMe());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(setPasswordFailure(response && response.data));

      throw error;
    });
};

export const FORGOT_PASSWORD_REQUEST = `${PREFIX} FORGOT_PASSWORD_REQUEST`;
export const FORGOT_PASSWORD_SUCCESS = `${PREFIX} FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_FAILURE = `${PREFIX} FORGOT_PASSWORD_FAILURE`;

const forgotPasswordRequest = () => ({
  type: FORGOT_PASSWORD_REQUEST,
});

const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
});

const forgotPasswordFailure = payload => ({
  payload,
  type: FORGOT_PASSWORD_FAILURE,
});

export const forgotPassword = values => dispatch => {
  dispatch(forgotPasswordRequest());

  return api.auth
    .forgotPassword(values)
    .then(({ data }) => {
      dispatch(forgotPasswordSuccess());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(forgotPasswordFailure(response && response.data));

      throw error;
    });
};
