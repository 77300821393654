import {
  CLEAR_ERROR,
  GENERATE_ERROR,
  GET_ME_FAILURE,
  GET_ME_REQUEST,
  GET_ME_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  SET_IS_AUTHENTICATED,
  SET_PASSWORD_FAILURE,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  USER_ACTION_REQUESTED,
} from '../actions/auth';

const initialState = {
  error: null,
  isAuthenticated: false,
  isError: false,
  isFetching: false,
  me: {},
  passwordChanged: false,
  requiredUserActions: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case GET_ME_REQUEST:
      return {
        ...state,
        error: null,
        isError: false,
        isFetching: true,
      };
    case SET_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
        isError: false,
        isFetching: true,
        passwordChanged: false,
      };
    case GET_ME_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
        me: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
      };
    case LOGIN_FAILURE:
    case GET_ME_FAILURE:
    case SET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        isAuthenticated: false,
        isError: true,
        isFetching: false,
      };
    case LOGOUT_USER: {
      return {
        ...state,
        isAuthenticated: false,
        me: {},
      };
    }
    case SET_IS_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    }
    case CLEAR_ERROR: {
      return {
        ...state,
        error: null,
        isError: false,
      };
    }
    case GENERATE_ERROR: {
      return {
        ...state,
        error: {
          detail: action.payload,
        },
        isError: true,
      };
    }
    case USER_ACTION_REQUESTED: {
      return {
        ...state,
        requiredUserActions: action.payload,
      };
    }
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        requiredUserActions: null,
        passwordChanged: true,
      };
    default:
      return state;
  }
};
