import {
  GET_FAILURE,
  GET_REQUEST,
  GET_SUCCESS,
  SET_FAILURE,
  SET_REQUEST,
  SET_SUCCESS,
} from '../actions/version';

const initialState = {
  data: null,
  error: null,
  isError: false,
  isFetching: false,
  isSending: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUEST:
      return {
        ...state,
        error: null,
        isError: false,
        isFetching: true,
      };
    case SET_REQUEST:
      return {
        ...state,
        error: null,
        isError: false,
        isSending: true,
      };
    case GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };
    case SET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSending: false,
      };
    case GET_FAILURE:
      return {
        ...state,
        data: null,
        error: action.payload,
        isError: true,
        isFetching: false,
      };
    case SET_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: true,
        isSending: false,
      };
    default:
      return state;
  }
};
