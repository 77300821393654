import api from '../../api';

import { BACKEND_PAGE_SIZE } from '../../common/constants';

const PREFIX = '[REGIONS]';

export const GET_LIST_REQUEST = `${PREFIX} GET_LIST_REQUEST`;
export const GET_LIST_SUCCESS = `${PREFIX} GET_LIST_SUCCESS`;
export const GET_LIST_FAILURE = `${PREFIX} GET_LIST_FAILURE`;

const getListRequest = () => ({
  type: GET_LIST_REQUEST,
});

const getListSuccess = payload => ({
  payload,
  type: GET_LIST_SUCCESS,
});

const getListFailure = payload => ({
  payload,
  type: GET_LIST_FAILURE,
});

export const getRegionsList = page => dispatch => {
  dispatch(getListRequest());

  return api.regions
    .list(page)
    .then(({ data }) => {
      dispatch(getListSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getListFailure(response && response.data));

      throw error;
    });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createRequest = () => ({
  type: CREATE_REQUEST,
});

const createSuccess = () => ({
  type: CREATE_SUCCESS,
});

const createFailure = payload => ({
  payload,
  type: CREATE_FAILURE,
});

export const createRegion = formData => (dispatch, getState) => {
  dispatch(createRequest());

  const lastPageNumber = Math.ceil((getState().regions.list.count + 1) / BACKEND_PAGE_SIZE);

  return api.regions
    .create(formData)
    .then(({ data }) => {
      dispatch(createSuccess());
      dispatch(getRegionsList(lastPageNumber));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(createFailure(response && response.data));

      throw error;
    });
};

export const EDIT_REQUEST = `${PREFIX} EDIT_REQUEST`;
export const EDIT_SUCCESS = `${PREFIX} EDIT_SUCCESS`;
export const EDIT_FAILURE = `${PREFIX} EDIT_FAILURE`;

const editRequest = () => ({
  type: EDIT_REQUEST,
});

const editSuccess = () => ({
  type: EDIT_SUCCESS,
});

const editFailure = payload => ({
  payload,
  type: EDIT_FAILURE,
});

export const editRegion = formData => (dispatch, getState) => {
  dispatch(editRequest());
  const {
    id, ...rest
  } = formData;

  const lastPageNumber = Math.ceil(getState().regions.list.count / BACKEND_PAGE_SIZE);

  return api.regions
    .updatePartial(id, rest)
    .then(({ data }) => {
      dispatch(editSuccess());
      dispatch(getRegionsList(lastPageNumber));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(editFailure(response && response.data));

      throw error;
    });
};

export const GET_FULL_LIST_REQUEST = `${PREFIX} GET_FULL_LIST_REQUEST`;
export const GET_FULL_LIST_SUCCESS = `${PREFIX} GET_FULL_LIST_SUCCESS`;
export const GET_FULL_LIST_FAILURE = `${PREFIX} GET_FULL_LIST_FAILURE`;

const getFullListRequest = () => ({
  type: GET_FULL_LIST_REQUEST,
});

const getFullListSuccess = payload => ({
  payload,
  type: GET_FULL_LIST_SUCCESS,
});

const getFullListFailure = payload => ({
  payload,
  type: GET_FULL_LIST_FAILURE,
});

export const getRegionsFullList = params => dispatch => {
  dispatch(getFullListRequest());

  return api.regions
    .fullList(params)
    .then(({ data }) => {
      dispatch(getFullListSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getFullListFailure(response && response.data));

      throw error;
    });
};

export const CLEAR_ERROR = `${PREFIX} CLEAR_ERROR`;

export const clearRequestError = () => ({
  type: CLEAR_ERROR,
});
