import api from '../../api';

const PREFIX = '[VERSION]';

export const GET_REQUEST = `${PREFIX} GET_REQUEST`;
export const GET_SUCCESS = `${PREFIX} GET_SUCCESS`;
export const GET_FAILURE = `${PREFIX} GET_FAILURE`;

const getVersionRequest = () => ({
  type: GET_REQUEST,
});

const getVersionSuccess = payload => ({
  payload,
  type: GET_SUCCESS,
});

const getVersionFailure = payload => ({
  payload,
  type: GET_FAILURE,
});

export const getVersion = () => dispatch => {
  dispatch(getVersionRequest());

  return api.version
    .getMinimumVersion()
    .then(({ data }) => {
      dispatch(getVersionSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getVersionFailure(response.data));

      throw error;
    });
};

export const SET_REQUEST = `${PREFIX} SET_REQUEST`;
export const SET_SUCCESS = `${PREFIX} SET_SUCCESS`;
export const SET_FAILURE = `${PREFIX} SET_FAILURE`;

const setVersionRequest = () => ({
  type: SET_REQUEST,
});

const setVersionSuccess = payload => ({
  payload,
  type: SET_SUCCESS,
});

const setVersionFailure = payload => ({
  payload,
  type: SET_FAILURE,
});

export const setVersion = formData => dispatch => {
  dispatch(setVersionRequest());

  return api.version
    .setMinimumVersion(formData)
    .then(({ data } = {}) => {
      dispatch(setVersionSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(setVersionFailure(response));

      throw error;
    });
};
