import request from '../utils/request';

const BASE_URL = 'api/v1/regions';

export default {
  create: data => request.post(`${BASE_URL}/`, data),
  delete: id => request.delete(`${BASE_URL}/${id}/`),
  fullList: params => request.get(`${BASE_URL}/all/${params || ''}`),
  getRegion: id => request.get(`${BASE_URL}/${id}/`),
  list: page => request.get(`${BASE_URL}/?page=${page}`),
  update: (id, data) => request.put(`${BASE_URL}/${id}/`, data),
  updatePartial: (id, data) => request.patch(`${BASE_URL}/${id}/`, data),
};
