import api from '../../api';

const PREFIX = '[MATERIALS]';

export const GET_LIST_REQUEST = `${PREFIX} GET_LIST_REQUEST`;
export const GET_LIST_SUCCESS = `${PREFIX} GET_LIST_SUCCESS`;
export const GET_LIST_FAILURE = `${PREFIX} GET_LIST_FAILURE`;

const getListRequest = () => ({
  type: GET_LIST_REQUEST,
});

const getListSuccess = payload => ({
  payload,
  type: GET_LIST_SUCCESS,
});

const getListFailure = payload => ({
  payload,
  type: GET_LIST_FAILURE,
});

export const getMaterialsList = (link, limit) => dispatch => {
  dispatch(getListRequest());

  return api.materials
    .list(link, limit)
    .then(({ data }) => {
      dispatch(getListSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getListFailure(response && response.data));

      throw error;
    });
};

export const CREATE_REQUEST = `${PREFIX} CREATE_REQUEST`;
export const CREATE_SUCCESS = `${PREFIX} CREATE_SUCCESS`;
export const CREATE_FAILURE = `${PREFIX} CREATE_FAILURE`;

const createRequest = () => ({
  type: CREATE_REQUEST,
});

const createSuccess = () => ({
  type: CREATE_SUCCESS,
});

const createFailure = payload => ({
  payload,
  type: CREATE_FAILURE,
});

export const createMaterial = formData => dispatch => {
  dispatch(createRequest());

  return api.materials
    .create(formData)
    .then(({ data }) => {
      dispatch(createSuccess());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(createFailure(response && response.data));

      throw error;
    });
};

export const GET_MATERIAL_REQUEST = `${PREFIX} GET_MATERIAL_REQUEST`;
export const GET_MATERIAL_SUCCESS = `${PREFIX} GET_MATERIAL_SUCCESS`;
export const GET_MATERIAL_FAILURE = `${PREFIX} GET_MATERIAL_FAILURE`;

const getMaterialRequest = () => ({
  type: GET_MATERIAL_REQUEST,
});

const getMaterialSuccess = payload => ({
  payload,
  type: GET_MATERIAL_SUCCESS,
});

const getMaterialFailure = payload => ({
  payload,
  type: GET_MATERIAL_FAILURE,
});

export const getMaterial = id => dispatch => {
  dispatch(getMaterialRequest());

  return api.materials
    .getItem(id)
    .then(({ data }) => {
      dispatch(getMaterialSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getMaterialFailure(response && response.data));

      throw error;
    });
};

export const EDIT_REQUEST = `${PREFIX} EDIT_REQUEST`;
export const EDIT_SUCCESS = `${PREFIX} EDIT_SUCCESS`;
export const EDIT_FAILURE = `${PREFIX} EDIT_FAILURE`;

const editRequest = () => ({
  type: EDIT_REQUEST,
});

const editSuccess = () => ({
  type: EDIT_SUCCESS,
});

const editFailure = payload => ({
  payload,
  type: EDIT_FAILURE,
});

export const editMaterial = (id, formData, productsToDelete) => dispatch => {
  dispatch(editRequest());

  return Promise.all(
    productsToDelete.map(
      productId => api.products.delete(productId)
    )
  ).then(() => api.materials
    .update(id, formData)
    .then(({ data }) => {
      dispatch(editSuccess());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(editFailure(response && response.data));

      throw error;
    }))
    .catch(error => {
      const { response } = error;

      dispatch(editFailure(response && response.data));

      throw error;
    });
};

export const DELETE_REQUEST = `${PREFIX} DELETE_REQUEST`;
export const DELETE_SUCCESS = `${PREFIX} DELETE_SUCCESS`;
export const DELETE_FAILURE = `${PREFIX} DELETE_FAILURE`;

const deleteRequest = () => ({
  type: DELETE_REQUEST,
});

const deleteSuccess = () => ({
  type: DELETE_SUCCESS,
});

const deleteFailure = payload => ({
  payload,
  type: DELETE_FAILURE,
});

export const deleteItem = id => dispatch => {
  dispatch(deleteRequest());

  return api.materials
    .delete(id)
    .then(({ data }) => {
      dispatch(deleteSuccess());

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(deleteFailure(response && response.data));

      throw error;
    });
};

export const CLEAR_CURRENT_MATERIAL = `${PREFIX} CLEAR_CURRENT_MATERIAL`;

export const clearCurrentMaterial = () => ({
  type: CLEAR_CURRENT_MATERIAL,
});

export const GET_OPTIONS_REQUEST = `${PREFIX} GET_OPTIONS_REQUEST`;
export const GET_OPTIONS_SUCCESS = `${PREFIX} GET_OPTIONS_SUCCESS`;
export const GET_OPTIONS_FAILURE = `${PREFIX} GET_OPTIONS_FAILURE`;

const getOptionsRequest = () => ({
  type: GET_OPTIONS_REQUEST,
});

const getOptionsSuccess = payload => ({
  payload,
  type: GET_OPTIONS_SUCCESS,
});

const getOptionsFailure = payload => ({
  payload,
  type: GET_OPTIONS_FAILURE,
});

export const getOptions = () => dispatch => {
  dispatch(getOptionsRequest());

  return api.materials
    .options()
    .then(({ data }) => {
      dispatch(getOptionsSuccess(data));

      return data;
    })
    .catch(error => {
      const { response } = error;

      dispatch(getOptionsFailure(response && response.data));

      throw error;
    });
};
